import React, { useEffect, useState } from "react"
import axios from "axios"
import { toast } from 'react-toastify'

import { Card, Tooltip, Typography } from "@material-tailwind/react"
import Pagination from "../../../../Components/Pagination"
import { ArrowDownTrayIcon,  } from "@heroicons/react/24/outline"
import { ArrowPathRoundedSquareIcon, InformationCircleIcon, TrashIcon } from "@heroicons/react/24/solid"

const TABLE_HEAD = ["Request ID", "Deal Name", "Requested by", "Status", "Actions"]

export function AmzDealsTable({isAltered}) {

    const [dealBatches, setDealBatches] = useState([])
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        axios.get('/api/amz-deals/all-batches')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get batches")
                } else {
                    setDealBatches(response.data.batches)
                    console.log(response.data.batches)
                } 
            })
            .catch(error => {
                console.error('Error:', error)
            })                                                    
    }, [isAltered, isChanged])

    const handleDeleteDeals = (batchId) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete this request: ${batchId}?`)

        if (isConfirmed) {
            axios.delete(`/api/amz-deals/delete/${batchId}`)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`)
                        setIsChanged(prevState => !prevState)
                    } else {
                        toast.error(`${response.data.message}`)
                        console.log('Error:', response.data.error)
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    }

    return (
    <div className="flex flex-col w-full h-full">
        <Card className="h-full w-full bg-black bg-opacity-50 rounded-b-none">
            <table className="w-full min-w-max table-auto text-center">
            <thead>
                <tr>
                {TABLE_HEAD.map((head) => (
                    <th key={head} className=" bg-[#1C2634] p-4">
                    <Typography
                        variant="small"
                        className="font-bold leading-none opacity-70 text-gray-200"
                    >
                        {head}
                    </Typography>
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                {dealBatches.map(({ batchId, dealsId, addedBy, status, createdAt }) => {
                const date = new Date(createdAt)
                const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
                        
                const options = { month: 'long', day: 'numeric', year: 'numeric' }
                const formattedDate = date.toLocaleDateString('en-US', options)
                const formattedDateTime = `${formattedDate} ${formattedTime}`

                return (
                    <tr key={batchId} className="even:bg-blue-gray-50/50">
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal">
                        {batchId}
                    </Typography>
                    </td>
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal">
                        {dealsId}
                    </Typography>
                    </td>
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal flex items-center justify-center">
                        <Tooltip content={`${formattedDateTime}`}>
                            <InformationCircleIcon className="h-5 w-5 mr-1"/> 
                        </Tooltip>                 
                        {addedBy}
                    </Typography>
                    </td>
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal">
                        {status.name}
                    </Typography>
                    </td>
                    <td className="p-4">
                        <button className="p-1 m-1 bg-blue-500 rounded-md text-white"><ArrowDownTrayIcon className="h-5 w-5"/></button>
                        <button className="p-1 m-1 bg-orange-500 rounded-md text-white"><ArrowPathRoundedSquareIcon className="h-5 w-5"/></button>
                        <button className="p-1 m-1 bg-red-500 rounded-md text-white" onClick={() => handleDeleteDeals(batchId)}><TrashIcon className="h-5 w-5"/></button>
                    </td>
                </tr>
                )})}
            </tbody>
            </table>
        </Card>
        <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    // currentPage={currentPage}
                    // customClass={`bg-opacity-50`}
                    // setCurrentPage={setCurrentPage}
                    // totalPages={totalPages}
                />
            </div>
    </div>

    );
}