import { useEffect, useState } from 'react'
import axios from 'axios'
import {  Card, Tooltip, Typography } from "@material-tailwind/react"
import Pagination from "../../../../Components/Pagination"
import { CheckBadgeIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import { ChatBubbleBottomCenterTextIcon, XCircleIcon } from '@heroicons/react/24/outline'
import RefDropdown from './RefDropdown'
import { RefMethodModal } from './RefMethodModal'
import SearchInput from '../../../../Components/SearchInput'

const TABLE_HEAD = ["Order ID", 'Refund Method', 'Actions', "RMA ID", "SKU", "Return Reason", <p>Buyer<br/>Comments</p>, <p>Outbound<br/>Shipping</p>, <p>Gift<br/>Badge</p>,<p>Replacement<br/>Badge</p>,
    <p>Amazon<br/>Refunded</p>, <p>Manually<br/>Refunded</p>, <p>Refund Total<br/>Amount</p>]

export function ManageReturnsTable({ batchId }) {

    const [orderMap, setOrderMap] = useState({});
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [refundMethods, setRefundMethods] = useState({});
    const [flattenedBatchList, setFlattenedBatchList] = useState({})
    
    const fetchReturnReason = async (returnReasonCode) => {
        try {
            const response = await axios.get(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/localize/code/${returnReasonCode}`)
            return response.data.value || 'no return code found'
        } catch (error) {
            console.error("Error fetching return reason:", error)
            return "Return reason code not found"
        }
    }
    
    useEffect(() => {
        const fetchBatchData = async () => {
            try {
                const [nexResponse, acResponse] = await Promise.all([
                    axios.get(`/api/refund-audits/get/${batchId}`),
                    axios.get(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/batch-content/${batchId}`)
                ])
    
                if (nexResponse.data.message || acResponse.data.message) {
                    console.log("Cannot get batches")
                } else {
                    const nexData = nexResponse.data.result.batchContent
                    const acData = acResponse.data[0].batchDetails
    
                    const tempBatchList = {}
                    const fetchReturnReasonPromises = []

                    const tempOrderMap = {}
                    nexData.forEach((batch, index) => {
                        tempOrderMap[batch.orderId] = index
                    })
                    setOrderMap(tempOrderMap)
                    
                    nexData.forEach(batch => {
                        const acBatch = acData.find(item => item._id === batch._id)
                    
                        if (acBatch && acBatch.manageReturnsData.length > 0) {
                            acBatch.manageReturnsData.forEach(returns => {
                                const orderId = batch?.orderId
                                const rmaId  = returns?.rmaId
                                const sku = returns?.returnRequestItems[0]?.merchantSKU
                                const returnReasonCode = returns?.returnRequestItems[0]?.returnReasonStringId
                                const buyerComments = returns?.returnRequestItems[0]?.customerComments
                                const giftBadge = returns?.giftReturn === false ? "No" : "Yes"
                                const outboundShip = returns?.outboundShipping === false ? "No" : "Yes"

                                const repResolution = returns?.returnRequestItems[0]?.resolution
                                const repOrderId = returns?.returnRequestItems[0]?.replacementOrderId
                                const repBadgeVal = repResolution === 'Replacement' && repOrderId ? 'Yes' : 'No'

                                const amzRefunded =  returns?.amazonRefunded ? 'Yes' : 'No'
                                const manualRefunded = returns?.manuallyRefunded ? 'Yes' : 'No'
                                const refTotalAmt = returns?.refundTotal?.totalAppliedRefund?.currencyValue || '....'

                                const fetchPromise = fetchReturnReason(returnReasonCode).then(returnReasonValue => {
                                tempBatchList[rmaId] = { orderId, rmaId, sku, returnReasonValue, buyerComments, outboundShip, repBadgeVal, giftBadge, amzRefunded, manualRefunded, refTotalAmt }
                                })
                                fetchReturnReasonPromises.push(fetchPromise)
                            })
                        }
                    })
                    await Promise.all(fetchReturnReasonPromises)
                    setFlattenedBatchList(tempBatchList)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }
        fetchBatchData()
    }, [])

    const flattenedBatchArray = Object.entries(flattenedBatchList).map(([orderId, batch]) => ({
        orderId,
        ...batch
    })).sort((a, b) => orderMap[a.orderId] - orderMap[b.orderId]);

    const handleSelectChange = (itemId, value) => {
        setRefundMethods(prevMethods => ({
            ...prevMethods,
            [itemId]: value
        }));
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const batchesPerPage = 15
    const totalPages = Math.ceil(flattenedBatchArray.length / batchesPerPage)
    const indexOfLastBatch = currentPage * batchesPerPage
    const indexOfFirstBatch = indexOfLastBatch - batchesPerPage

    const filteredBatches = flattenedBatchArray.filter(item =>
        (item.orderId && item.orderId.toString().includes(searchTerm.trim())) ||
        (item.rmaId && item.rmaId.toString().includes(searchTerm.trim())) ||
        (item.sku && item.sku.toLowerCase().includes(searchTerm.toLowerCase())) 
    )

    const batchData = filteredBatches.slice(indexOfFirstBatch, indexOfLastBatch)

    return (
        <div className="flex flex-col min-h-full max-h-[48rem] w-full rounded-t-md overflow-hidden">
                <div className="flex items-center justify-between w-full">
                    <SearchInput  value={searchTerm} onChange={handleSearch}/>
                    <p className='text-white font-bold text-xl flex items-center'><TableCellsIcon className='h-7 w-7 mr-1'/>Manage Returns</p>
                    <div className='flex items-center'>
                        <p className="font-semibold mr-5 text-white">Total Items: <span className="text-blue-500">{filteredBatches.length}</span></p>
                    </div>
                </div>
            <Card className="h-[37rem] min-w-full max-w-[90.4rem] bg-[#101927] bg-opacity-70 rounded-b-none overflow-y-auto">
                <table className="w-full min-w-max table-auto text-center">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th key={head} className={`border-b border-[#374050] bg-[#1E2936] bg-opacity-90 p-4 sticky top-0 z-10
                                    ${head === 'Order Id' ? 'left-0 z-20' : ''}`}>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="font-semibold leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {flattenedBatchArray.length <= 0 ?
                            <>
                                {[...Array(15)].map((_, index) => (
                                    <tr key={index} className='border-y bg-blue-gray-600 border-[#374050] animate-pulse'>
                                        <td colSpan="13" className="p-4">
                                            <div className="h-[2rem] rounded"></div>
                                        </td>
                                    </tr>
                                ))}
                            </> :
                                (batchData.map((item) => (
                                    <tr key={item.rmaId} className="border-y border-[#374050] hover:bg-blue-900">
                                        <td className="p-4 sticky left-0 z-5 bg-[#101927]">
                                            <Typography variant="small" color="white" className="font-bold">
                                                {item.orderId}
                                            </Typography>
                                        </td>
                                        <td className="p-4  sticky left-[10.5rem] z-5 bg-[#101927]">
                                            <RefDropdown
                                                itemId={item.rmaId}
                                                onSelectChange={handleSelectChange}
                                            />
                                        </td>
                                        <td className="p-4  sticky left-[24rem] z-5 bg-[#101927]">
                                            <RefMethodModal
                                                data={item}
                                                refundMethod={refundMethods[item.rmaId] || 'regRef'}
                                            />
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {item.rmaId}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {item.sku}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {item.returnReasonValue}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                        <Tooltip 
                                        className="border border-blue-gray-50 text-black bg-white px-4 py-3 shadow-xl shadow-black/10"
                                        content={          
                                            <Typography color="blue-gray" className="font-medium">
                                                {item.buyerComments}
                                            </Typography>}>
                                            <ChatBubbleBottomCenterTextIcon className='h-6 w-full font-bold text-blue-500'/>
                                        </Tooltip>

                                        </td>
                                        <td className="p-4">
                                            {item.outboundShip === 'Yes' ? <CheckBadgeIcon className='h-6 w-full text-green-500'/> 
                                            :  <XCircleIcon className='h-6 w-full text-gray-500'/>}
                                        </td>
                                        <td className="p-4">
                                            {item.giftBadge === 'Yes' ? <CheckBadgeIcon className='h-6 w-full text-green-500'/> 
                                            :  <XCircleIcon className='h-6 w-full text-gray-500'/>}
                                        </td>
                                        <td className="p-4">
                                        {item.repBadgeVal === 'Yes' ? <CheckBadgeIcon className='h-6 w-full text-green-500'/> 
                                            :  <XCircleIcon className='h-6 w-full text-gray-500'/>}
                                        </td>
                                        <td className="p-4">
                                        {item.amzRefunded === 'Yes' ? <CheckBadgeIcon className='h-6 w-full text-green-500'/> 
                                            :  <XCircleIcon className='h-6 w-full text-gray-500'/>}
                                        </td>
                                        <td className="p-4">
                                        {item.manualRefunded === 'Yes' ? <CheckBadgeIcon className='h-6 w-full text-green-500'/> 
                                            :  <XCircleIcon className='h-6 w-full text-gray-500'/>}
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color='white' className="font-normal">
                                                $ {item.refTotalAmt}
                                            </Typography>
                                        </td>
                                    </tr>
                                )))         
                            }
                    </tbody>
                </table>
            </Card>
            <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                />
            </div>
        </div>
    )
}

