import React, { useState } from "react";
import axios from "axios";

import { Popover, PopoverHandler, PopoverContent, Chip, Typography, Avatar } from "@material-tailwind/react";
import { BriefcaseIcon, EyeIcon } from "@heroicons/react/24/solid";
import amz from '../../../assets/amzLogo.jpg'

export function PopViewThread({ caseId, shortDesc }) {
  const [openPopover, setOpenPopover] = useState(false);
  const [data, setData] = useState('');
  const [threads, setThreads] = useState([])

  const togglePopover = async () => {
    setOpenPopover(!openPopover);

    try {
      const response = await axios.get(`https://api-central.outdoorequippedservice.com/api/v1/amazon-cases/view-threads/${caseId}`);
      setData(response.data[0])
      setThreads(response.data[0].threads)
      console.log('fetch result: ', response.data[0])
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Popover open={openPopover} handler={togglePopover} placement="left-end">

      <PopoverHandler>
        <button className="bg-blue-500 rounded-lg p-1 m-1 px-2" onClick={togglePopover}><EyeIcon className="h-5 w-5"/></button> 
      </PopoverHandler>
      <PopoverContent className="z-50 h-[50rem] w-[70%] overflow-y-auto bg-[#F8F8FF] bg-opacity-70 border-none border-[#22D2EF]">
        <div className="mb-2 flex items-center gap-3 w-fit px-2 p-1 sticky top-0 z-10 bg-[#FFFAFA] bg-opacity-90 shadow-xl rounded-md">
          <Typography
            as="a"
            href="#"
            variant="h4"
            color="black"
            className="font-bold transition-colors hover:text-gray-900 flex items-center"
          >
            Case ID: {data.caseId || <BriefcaseIcon className="animate-pulse h-6 w-6"/>} 
          </Typography>
          <Chip
            value={`Reply threads: ${threads.length}`}
            variant="gradient"
            color="blue"
            className="rounded-full px-2 py-1 font-medium capitalize tracking-wide"
          />
        </div>
        <div className="mb-2 flex items-center gap-3 w-fit px-2 p-1 bg-[#FFFAFA] bg-opacity-90 shadow-xl rounded-md">
          <Typography color="blue"  className="font-bold transition-colors flex items-center">
            Description: {shortDesc}
          </Typography>
        </div>
        {threads.map(({ message, sender, timestamp }, index) => {
            const date = new Date(timestamp * 1000);
            const formattedDate = date.toLocaleString();

          return (
              <div className="flex flex-col items-start border rounded-md w-fit border-gray-300 bg-[#FFFAFA] shadow-xl p-2 m-1 my-3" key={index}>
              <div className="flex items-center gap-5">
              <Typography
              variant="medium"
              color="black"
              className=" font-bold flex items-center"
              >
                <Avatar
                  src= {sender === 'Amazon' ? amz : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
                  alt="avatar"
                  withBorder={true}
                  color='blue'
                  className="p-0.5 mr-2"
                /> 
                '{sender}'
              </Typography>
              <Typography
              variant="small"
              color="gray"
              className="font-medium ml-20"
              >
                Date: {formattedDate}
              </Typography>
              </div>

              <Typography
              variant="small"
              color="gray"
              className=" mt-7"
              >
                <pre className="text-black font-thin font-sans">{ message }</pre>
              </Typography>
            </div>
          )})}

      </PopoverContent>
    </Popover>
  );
}