import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'

import neural2 from '../../../../assets/neural-networks.webp'
import { AddDeals } from './AddDeals'
import { AmzDealsTable } from './AmzDealsTable'
import FloatBar from '../../../../Components/FloatBar'
import SideButton from '../../../../Components/SideButton'

const AmazonDeals = () => {

    const [isAltered, setIsAltered] = useState(false)

    const handleAlterCallBack = () => {
        setIsAltered(prevstate => !prevstate)
    }

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.8)), url(${neural2})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

        <FloatBar/>
            <div className='flex flex-row gap-2 w-full h-full  '>
                <SideButton/>
                <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
                    <ToastContainer/>
                    <p className='text-2xl font-semibold text-white'>Amazon Deals</p>
                    <div className='w-full h-fit flex flex-row items-center gap-8 text-white pl-4 my-3'>
                        <AddDeals handleAlterCallBack={handleAlterCallBack}/>
                    </div>
                    <div className='w-full h-full p-2 pl-4 flex justify-center m-1 overflow-y-auto overflow-x-auto'>
                        <AmzDealsTable isAltered={isAltered}/>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default AmazonDeals