import React, { useState, useEffect } from 'react'
import axios from 'axios';
import networkbg from '../../assets/networkbg.png'
import FloatBar from '../../Components/FloatBar';
import AdminSidebar from './Components/AdminSidebar';
import { RectangleGroupIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import SideButton from '../../Components/SideButton';

const AdminPage = () => {
    
    const [users, setUsers] = useState([]);
    const [departments, setDepartments] = useState([]);

    const deptsCount = departments.length 
    const activeUsersCount = users.filter(user => user.isActive).length;

    useEffect(() => {
        Promise.all([
            axios.get('/api/users/get-users?role=&isActive='),
            axios.get('/api/departments/get')
        ])
        .then(([usersResponse, departmentsResponse]) => {
            if (usersResponse.data.message) {
                console.log("Cannot get users");
            } else {
                setUsers(usersResponse.data.result);
            }
    
            if (departmentsResponse.data.message) {
                console.log('Cannot get departments');
            } else {              
                setDepartments(departmentsResponse.data.result);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, []);
    

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-flex-start items-center flex-col gap-1  relative overflow-hidden text-white'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.99), rgba(17, 24, 38, 0.8)), url(${networkbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            <FloatBar/>
                                                            
            <div className='flex flex-row w-full h-full bg-opacity-30 p-2'>
                <SideButton/>
                <div className='w-full h-full flex  flex-col p-1 pl-4 '>
                <p className='text-4xl font-semibold'>Dashboard</p>

                <div class="grid grid-cols-2 w-[700px] gap-2 max-[500px]:grid-cols-1 px-3 mt-14">
                    <Link to='/adminNXS0011/manage-users'>
                        <div class="group w-full rounded-lg bg-[#673ab7] p-5 transition relative duration-300 cursor-pointer hover:translate-y-[3px] hover:shadow-[0_-8px_0px_0px_#2196f3]">
                            <p class="text-white text-2xl">{activeUsersCount}</p>
                            <p class="text-white text-sm">Active Users</p>
                            <UserGroupIcon className='h-10 w-10 group-hover:opacity-100 absolute right-[10%] top-[50%] translate-y-[-50%] opacity-20 transition group-hover:scale-110 duration-300'/>
                        </div>
                    </Link>

                    <Link to='/adminNXS0011/manage-departments'>
                        <div class="group w-full rounded-lg bg-[rgb(41,49,79)] p-5 transition relative duration-300 cursor-pointer hover:translate-y-[3px] hover:shadow-[0_-8px_0px_0px_rgb(244,67,54)]">
                            <p class="text-white text-2xl">{deptsCount}</p>
                            <p class="text-white text-sm">Departments</p>
                            <RectangleGroupIcon className='h-10 w-10 group-hover:opacity-100 absolute right-[10%] top-[50%] translate-y-[-50%] opacity-20 transition group-hover:scale-110 duration-300'/>
                        </div>
                    </Link>

                    </div>

                </div>
            </div>
            
        </div>
    )
}

export default AdminPage