import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { FaHome } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';
import { LuLogOut } from "react-icons/lu";
import { useUser } from '../store/userState';

const SideButton = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = useUser()

	const isActiveNavLink = (path) => {
		return location.pathname.startsWith(path);
    }

    const handlePreviousPage = () => {
        navigate(-1)
    }

    const role = user.role

    const handleLogout = () => {
        const confirmLogout = window.confirm("Are you sure you want to log out?");

        if(confirmLogout){
            localStorage.removeItem('jwt')
            localStorage.removeItem('id')
            navigate('/login')
        }

    }

    return (
        <div className='flex flex-col gap-2 items-center justify-center  h-full'>

            { role === 'Admin' ? 
                <Link to='/adminNXS0011/dashboard'>
                    <button className={`bg-white w-16 h-14 hover:scale-110 hover:text-blue-500 transition bg-opacity-30 backdrop-filter backdrop-blur-sm flex items-center justify-center rounded-r-md
                                    ${ isActiveNavLink("/adminNXS0011/dashboard") ? "text-blue-500" : 
                                    isActiveNavLink("/adminNXS0011/manage-users") ? "text-blue-500" :
                                    isActiveNavLink("/adminNXS0011/manage-departments") ? "text-blue-500" : 
                                    "text-white"}`}>
                        <MdAdminPanelSettings className='h-8 w-8'/>
                    </button>
                </Link>
            : 
                ''
            } 

            <Link to='/home'>
                <button className={`bg-white w-16 h-14 hover:scale-110 hover:text-blue-500 transition bg-opacity-30 backdrop-filter backdrop-blur-sm flex items-center justify-center rounded-r-md
                                ${isActiveNavLink("/home") ? "text-blue-500" : "text-white"}`}>
                    <FaHome className='h-8 w-8'/>
                </button>
            </Link>

            {!isActiveNavLink("/home") ?
                <button className={`bg-white  w-16 h-14 hover:scale-110 hover:text-blue-500 transition bg-opacity-30 backdrop-filter backdrop-blur-sm flex items-center justify-center rounded-r-md
                    ${isActiveNavLink("/home") ? "text-blue-500" : "text-white"}`}
                    onClick={handlePreviousPage}>
                    <ArrowUturnLeftIcon className='h-8 w-8' />
                </button>
            :
                ""
            }

                <button onClick={handleLogout} className={`bg-white w-16 h-14 hover:scale-110 transition bg-opacity-30 backdrop-filter backdrop-blur-sm flex items-center 
                    text-white hover:text-orange-500 justify-center rounded-r-md`}>
                    <LuLogOut className='h-8 w-8 '/>
                </button>

        </div>

    )
}

export default SideButton
