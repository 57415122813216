import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Sidebar from '../../Components/Sidebar'
import FloatBar from '../../Components/FloatBar'
import DepartmentCards from '../../Components/DepartmentCards'
import neural1 from '../../assets/404neural.webp'
import { Avatar, Tooltip } from '@material-tailwind/react'
import SideButton from '../../Components/SideButton'

const Home = () => {

    const [userDept, setUserDept]= useState([])
    const [departments, setDepartments] = useState([])
    const [departmentTools, setDepartmentTools] = useState([])

    useEffect(() => {     
        axios.get('/api/users/get-users?role=&isActive=')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get users")
                } else {
                    setUserDept(response.data.result) 
                }
                
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [])

    const maxAvatars = 9;

    useEffect(() => {
        axios.get('/api/departments/get')
        .then(response => {
            if (response.data.message) {
                console.log('Cannot get departments')
            } else {              
                setDepartments(response.data.result)
                const departmentToolsArray = response.data.result.map(department => department.tools)
                setDepartmentTools(departmentToolsArray)          
            }
        })
    }, [])

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1 relative overflow-y-auto'
            style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.8), rgba(17, 24, 38, 0.7)), url(${neural1})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

            <FloatBar/>
            <div className='flex flex-row gap-2 w-full h-full items-center '>
                {/* <Sidebar/> */}
                <SideButton/>
                <div className='flex items-center justify-around w-full h-full flex-col'>
                <div className='w-[100%] h-fit gap-4 flex flex-wrap items-center justify-center p-10'>

                { departments.length <= 0 ?
                    <>
                        <div className="container">
                            <div className="dot dot-1"></div>
                            <div className="dot dot-2"></div>
                            <div className="dot dot-3"></div>
                        </div>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter id="goo">
                            <feGaussianBlur
                                result="blur"
                                stdDeviation="10"
                                in="SourceGraphic"
                            ></feGaussianBlur>
                            <feColorMatrix
                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
                                mode="matrix"
                                in="blur"
                            ></feColorMatrix>
                            </filter>
                        </defs>
                        </svg> 
                    </>
                        : 
                    (departments.map(({ department }, index) => (
                        <DepartmentCards key={index} department={department} deptTools={departmentTools} index={index}/>
                    )))
                }



                    </div>
                    <div className='flex justify-center  backdrop-filter backdrop-blur-sm flex-row-reverse items-center rounded-md w-[80%] p-2 gap-2 bg-white bg-opacity-15 border-y border-[#22D2EF]'>
                        <div className='flex flex-col'>
                            <p className='bg-clip-text text-transparent bg-gradient-to-l from-[#FF44EC] to-[#44BCFF] text-2xl font-bold '>Admins/Devs</p>
                            <div class="flex gap-3 pt-2 pl-3">
                                <svg stroke="currentColor" viewBox="0 0 24 24" class="w-4 hover:scale-125 duration-200 fill-white stroke-2">
                                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22">
                                    </path>
                                </svg>
                                <svg stroke="currentColor" viewBox="0 0 24 24" class="w-4 hover:scale-125 duration-200 fill-white stroke-2">
                                    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z">
                                    </path>
                                    <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                                </svg>
                                <svg stroke="currentColor" viewBox="0 0 24 24" class="w-4 hover:scale-125 duration-200 fill-white stroke-2">
                                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                    </path>
                                </svg>
                                <svg stroke="currentColor" viewBox="0 0 24 24" class="w-4 hover:scale-125 duration-200 fill-white stroke-2">
                                    <path d="M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7"></path>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="p-2 flex items-center -space-x-4">
                        {userDept.length <= 0 ?
                            <>
                                {[...Array(4)].map((_, index) => (
                                    <Avatar
                                    key={index}
                                    size='md'
                                    variant="circular"
                                    alt="Avatar"
                                    className="border-2 animate-pulse border-white border-opacity-20 hover:z-10 focus:z-10"
                                    src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                />
                                ))}
                            </> 
                            :
                            (userDept.filter(user => user.permissions[0].department === 'Admin Access').slice(0, maxAvatars).map(({ profileImage, firstName, lastName }, index) => (
                                <Tooltip content={`${firstName} ${lastName}`}>
                                    <Avatar
                                        key={index}
                                        size='md'
                                        variant="circular"
                                        alt={firstName}
                                        className="border-2 border-white hover:z-10 focus:z-10"
                                        src={profileImage ? `https://nexus-portal.s3.amazonaws.com/profileImages/${profileImage}` : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"}
                                    />
                                </Tooltip>
                                )))
                            }

                            </div>
                            {userDept.filter(user => user.permissions[0].department === 'Admin Access').length > maxAvatars && (
                                <div className="text-sm text-gray-500 z-10">
                                    +{userDept.filter(user => user.permissions[0].department === 'Admin Access').length - maxAvatars} more
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

