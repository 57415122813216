import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify';

import networkbg from '../../assets/networkbg.png'
import FloatBar from '../../Components/FloatBar';
import AdminSidebar from './Components/AdminSidebar';
import UserManagementTable from './Components/UserManagementTable';
import AddUsers from './Components/AddUsers';
import SideButton from '../../Components/SideButton';

const ManageUsersView = () => {
    const [isEditable, setIsEditable] = useState(false);
    const [updateTable, setUpdateTable] = useState(false);

    const updateIsEditable = (value) => {
        setIsEditable(value);
    };

    const toggleUpdateTable = () => {
        setUpdateTable(prevState => !prevState);
    };

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-flex-start items-center flex-col gap-1  relative overflow-hidden text-white'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.99), rgba(17, 24, 38, 0.8)), url(${networkbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            <ToastContainer/>
            <FloatBar/>

            <div className='flex flex-row w-full h-full bg-opacity-30 p-2'>
                { isEditable ? '' : <SideButton/> } 
                <div className='w-full h-full flex items-center flex-col gap-5 p-1 pl-4 overflow-y-auto'>
                    <div className='w-full h-fit flex flex-row gap-8 text-white mb-2'>
                        <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10'>User Management</button>
                        <AddUsers updateTable={toggleUpdateTable} />
                    </div>
                    <UserManagementTable updateIsEditable={updateIsEditable} updateTable={updateTable} />
                </div>
            </div>
            
        </div>
    )
}

export default ManageUsersView