import React, { useState } from "react"
import axios from "axios";

import { toast } from 'react-toastify'
import { useUser } from "../../../../store/userState";
import generateTimestamp from "../../../../middlewares/GlobalUtils/generateTimeStamp";

import {Button, Dialog, DialogHeader, DialogBody, DialogFooter, Input, } from "@material-tailwind/react";
import { FaAmazon } from "react-icons/fa";

export function AddDeals({handleAlterCallBack}) {
  const [open, setOpen] = useState(false)
  const [newDeals, setNewDeals] = useState('')

  const { user} = useUser()
  const userEmail = user.email

  const handleOpen = () => setOpen(!open)

  const handleSetDeals = (event) => setNewDeals(event.target.value)

  const handleAddDeals = () => {

    const batchData = {
      batchId: generateTimestamp(),
      dealsId: newDeals ,
      addedBy: userEmail
    }

    axios.post('/api/amz-deals/add', batchData)
    .then(response => {
      if (response.data.message) {
          toast.success(`${response.data.message}`)
          handleAlterCallBack()
          setNewDeals('')
          setOpen(!open)
      } else {
          toast.error(`${response.data.message}`)
      }
    })
    .catch(error => {
        console.error('Error:', error)
        const errorMessage = error.response?.data?.message || 'An error occurred during add.'
        toast.error(errorMessage)
    })

  }

  return (
    <>
      <Button onClick={handleOpen} variant="gradient" color="lime">
        Add new deal
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader className="bg-lime-400 rounded-t-md text-black">New Deals</DialogHeader>
        <DialogBody className="flex justify-center">
          <div className="w-72">
            <Input 
              value={newDeals}
              onChange={handleSetDeals}
              label="Deal Id" 
              icon={<FaAmazon className="h-5 w-5"/>} />
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleAddDeals}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}