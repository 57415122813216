import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'

import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../store/userState';

import nexus from '../assets/nexusLogo.png'
import { Avatar, Typography } from '@material-tailwind/react';
import { FaUserCircle } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";


const FloatBar = () => {

  const [userImgUrl, setUserImgUrl] = useState()
  const [overlayIsOpen, setOverlayIsOpen] = useState(false)

  const { user } = useUser()
  const profImageLink = `https://nexus-portal.s3.amazonaws.com/profileImages/${userImgUrl}`

  useEffect(() => {
    axios.get(`/api/users/get-user/${user.id}`)
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get users")
            } else {
                setUserImgUrl(response.data.profileImage)
            }
        })
        .catch(error => {
            console.error('Error:', error)
        })
  })

  const overlayRef = useRef(null)
  const toggleButtonRef = useRef(null)

  const navigate = useNavigate()

  const toggleUserOverlay = () => {
    setOverlayIsOpen(prevState => !prevState)
    console.log(user)
  }

  const handleClickOutside = (event) => {
      if (
          overlayRef.current &&
          !overlayRef.current.contains(event.target) &&
          toggleButtonRef.current &&
          !toggleButtonRef.current.contains(event.target)
      ) {
          setOverlayIsOpen(false)
      }
  }

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");

    if(confirmLogout){
        localStorage.removeItem('jwt')
        localStorage.removeItem('id')
        navigate('/login')
    }

}

  useEffect(() => {
      if (overlayIsOpen) {
          document.addEventListener('mousedown', handleClickOutside)
      } else {
          document.removeEventListener('mousedown', handleClickOutside)
      }
      return () => {
          document.removeEventListener('mousedown', handleClickOutside)
      }
  }, [overlayIsOpen])

  return (
    <div className='w-full h-[80px] text-white border-b  flex items-center justify-between gap-1 p-2 border-[#232B3C]'>
      <Link to='/home'>
        <div className='flex bg-cover w-[10rem] h-[3rem] p-1 ml-[2rem]'>
          <img src={nexus} className="w-full h-full"/>
        </div>
      </Link>
      {/* <Avatar
        src={profImageLink}
        alt="avatar"
        className="p-0.5 border-2 border-[#22D2EF]"
      /> */}

      <div className="flex flex-col gap-6 mr-10">
        <div className="flex items-center gap-4">
          <div>
              <Typography variant="h6">Welcome, {user.firstName}!</Typography>
              <Typography variant="small" color="gray" className="font-normal">
                {user.role}
              </Typography>
            </div>
          <Avatar
            src={profImageLink ? profImageLink : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
            alt="avatar"
            className="p-0.5 border-2 border-[#22D2EF] cursor-pointer"
            onClick={toggleUserOverlay}
            ref={toggleButtonRef}
          />

          {overlayIsOpen && (
            <div ref={overlayRef} className='absolute top-[4.5rem] right-14 z-10 w-60 h-fit p-2 bg-white text-white text-center rounded-md flex flex-col items-center shadow-lg 
              bg-opacity-30 backdrop-filter backdrop-blur-sm overflow-y-auto'>
              <p className='text-md flex items-center font-semibold'><span className='w-2 h-2 mr-1 rounded-full bg-green-500'/>{user.firstName} {user.lastName}</p>
              <p className='text-sm text-gray-500 mb-5'>{user.email}</p>
              <Link to='/profile-settings'>
              <button className='text-md flex items-center cursor-pointer m-1 hover:bg-light-accent-color p-2 rounded-md hover:bg-blue-500'
                >
                <FaUserCircle className='h-4 w-4 mr-1'/>My Profile
              </button>
              </Link>
              <button onClick={handleLogout} className='text-md flex items-center cursor-pointer m-1 hover:bg-light-accent-color p-2 rounded-md hover:bg-blue-500'>
                <LuLogOut className='h-4 w-4 mr-1'/>Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FloatBar