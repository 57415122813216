import React from 'react'
import neural from '../../assets/404neural.webp'
import { Link, useNavigate } from 'react-router-dom'

const Errorpage = () => {
  const navigate = useNavigate()

  const handlePreviousPage = () => {
      navigate(-1)
  }

  return (
  <section className='w-screen h-screen bg-[#111926] gap-1 relative overflow-hidden' style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.9), rgba(17, 24, 38, 0.7)), url(${neural})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
    <div className="w-full h-full flex items-center justify-center flex-col">
        <p className="text-lg font-semibold text-blue-500">404 error</p>
        <h1 className="mt-3 text-2xl font-semibold text-white dark:text-white md:text-3xl">Page not found</h1>
        <p className="mt-4 text-gray-500 dark:text-gray-400">Sorry, the page you are looking for doesn't exist.</p>

        <div className="flex items-center mt-6 gap-x-3">
          <button onClick={handlePreviousPage} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 ">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>
              <span>Go back</span>
          </button>

          <Link to="/home">
            <button className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
              Take me home
            </button>
          </Link>
      </div>

      <div className="relative w-full mt-12 lg:w-1/2 lg:mt-0">
          <img className="w-full max-w-lg lg:mx-auto" src="/images/components/illustration.svg" alt=""/>
      </div>
    </div>
  </section>
  )
}

export default Errorpage

