import axios from 'axios'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import React, { useEffect, useState } from 'react'
import { userActivityStateWH } from '../../../store/userActivityState'
import exportData from '../../../middlewares/Warehouse/exportToXlsx'

import { Card, Spinner, Tooltip, Typography } from "@material-tailwind/react"
import SearchInput from "../../../Components/SearchInput"
import Pagination from "../../../Components/Pagination"
import { ArrowDownTrayIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, EyeIcon, InformationCircleIcon, TrashIcon } from "@heroicons/react/24/solid"
import { Link } from 'react-router-dom'

const TABLE_HEAD = ["Batch ID", "Uploaded By", "Category", "Status", "Actions"]

export function TransactionsTable({ isAltereds }) {

    const [downloadingBatch, setDownloadingBatch] = useState({});
    const [batchList, setBatchList] = useState([])
    const [isAltered, setIsAltered] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [batchContentData, setBatchContentData] = useState({})

    const lastActivity = useRecoilValue(userActivityStateWH)

    const batchesPerPage = 10
    const totalPages = Math.ceil(batchList.length / batchesPerPage)
    const indexOfLastBatch = currentPage * batchesPerPage
    const indexOfFirstBatch = indexOfLastBatch - batchesPerPage

    const filteredBatches = batchList.filter(item =>
        (item.batchId && item.batchId.toString().includes(searchTerm.trim())) ||
        (item.addedBy && item.addedBy.toLowerCase().includes(searchTerm.toLowerCase())) 
    )
    filteredBatches.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    const batchData = filteredBatches.slice(indexOfFirstBatch, indexOfLastBatch)

    useEffect(() => {
        const fetchBatchContent = async (batchId) => {
            try {
                const response = await axios.get(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/batch/processed-items/${batchId}`)
                const batchContentAc = response.data
                setBatchContentData(prevData => ({ ...prevData, [batchId]: batchContentAc }))
            } catch (error) {
                console.error('Error fetching batch content:', error)
                setBatchContentData(prevData => ({ ...prevData, [batchId]: [] }))
            }
        }
        batchData.forEach(({ batchId, status }) => {
            if (status.name === 'in-progress' || status.name === 'completed') {
                fetchBatchContent(batchId)
            }
        })
    }, [isAltered])

    useEffect(() => {
        const toggleInterval = setInterval(() => {
            setIsAltered(prevState => !prevState)
        }, 2000) 
        return () => {
            clearInterval(toggleInterval)
        }
    }, [])

    useEffect(() => {
        axios.get('/api/refund-audits/all-batches')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get batches")
                } else {
                    setBatchList(response.data.result)
                } 
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [isAltered, isAltereds])

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const handleExportData = async (batchId, category) => {
        setDownloadingBatch(prevState => ({ ...prevState, [batchId]: true }));
        await exportData(batchId, category);
        setDownloadingBatch(prevState => ({ ...prevState, [batchId]: false }));
    }

    const handleReopenBatch = (batchId) => {

        const updatedStatus = {
            status: {
                name: 'open',
                message: 'OK!'
            }
        };
        
        axios.put(`/api/refund-audits/update/${batchId}`, updatedStatus)
            .then(response => {
                if(response.data.message) {
                    setIsAltered(prevState => !prevState)
                    console.log('repoening', batchId)
                    axios.delete(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/batch/${batchId}`)
                    .then(response => {console.log(response.data.result)})
                }else {
                    toast.error(`${response.data.message}`)
                    console.log('Error:', response.data.error)
                }
            })
    }

    const handleDeleteBatch = (batchId) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete this batch: ${batchId}?`)

        if (isConfirmed) {
            axios.delete(`/api/refund-audits/delete/${batchId}`)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`)
                        setIsAltered(prevState => !prevState)
                        axios.delete(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/batch/${batchId}`)
                        .then(response => {console.log(response.data.result)})
                    } else {
                        toast.error(`${response.data.message}`)
                        console.log('Error:', response.data.error)
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    };

    return (
        <div className="flex flex-col w-full h-full">
            <div className='flex items-center justify-between text-white'>
                <div className="flex items-center justify-between w-full">
                    <SearchInput  value={searchTerm} onChange={handleSearch}/>
                    <div className='flex items-center'>
                    <p className="font-semibold mr-5">Last activity <span className="text-gray-500">{lastActivity === 'NaN min ago' ? '0 min ago' : lastActivity}</span></p>
                        <p className="font-semibold mr-5">Total Batches: <span className="text-blue-500">{batchList.length}</span></p>
                        <div className='flex items-center p-2 flex-row justify-between w-[10rem]'>
                            <span className='animate-pulse'>Auto Refresh</span>
                            <div class="three-body">
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Card className="h-[37rem] w-full overflow-y-auto overflow-x-auto bg-opacity-70 bg-[#101927] rounded-b-none">
            <table className="w-full min-w-max table-auto text-center">
                <thead>
                <tr>
                    {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-[#374050] bg-[#1E2936]  p-4 bg-opacity-90 sticky top-0 z-10">
                        <Typography
                        variant="small"
                        color="white"
                        className="font-normal leading-none opacity-70"
                        >
                        {head}
                        </Typography>
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                    { batchList.length <= 0 ? (
                    <>
                        {[...Array(9)].map((_, index) => (
                            <tr key={index} className='even:bg-blue-gray-700 animate-pulse'>
                                <td colSpan="7" className="p-4 animate-pulse">
                                    <div className="h-[2rem] rounded"></div>
                                </td>
                            </tr>
                        ))}
                    </>
                )  :                   
                    (batchData.map(({ batchId, status, addedBy, createdAt, batchContent, categories }) => {
                        const batchContentAc = batchContentData[batchId] || '0'
                        const date = new Date(createdAt)
                        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
                        
                        const options = { month: 'long', day: 'numeric', year: 'numeric' }
                        const formattedDate = date.toLocaleDateString('en-US', options)
                        const formattedDateTime = `${formattedDate} ${formattedTime}`

                        const categoryLabel = 
                        categories.includes('manageInventory') && categories.includes('manageOrders') ? 'Manage Inventory, Manage Orders'
                        : categories.includes('manageInventory') && categories.includes('manageReturns') ? 'Manage Inventory, Manage Returns'
                        : categories.includes('manageOrders') && categories.includes('manageReturns') ? 'Manage Orders, Manage Returns'
                        : categories.includes('manageInventory') ? 'Manage Inventory'
                        : categories.includes('manageOrders') ? 'Manage Orders'
                        : categories.includes('manageReturns') ? 'Manage Returns'
                        : 'All'

                        const refundAction = categories.includes('all') ? true : false

                        return (
                            <tr key={batchId} className="even:bg-blue-gray-700 hover:bg-blue-900">
                                <td className="p-1">
                                    <Typography variant="small" color="white" className="font-normal flex items-center justify-center">
                                        {batchId}
                                        <Tooltip content={formattedDateTime}>
                                            <InformationCircleIcon className='h-5 w-5 ml-1'/>
                                        </Tooltip>
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="white" className="font-normal">
                                        {addedBy}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="white" className="font-normal">
                                        {categoryLabel}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="white" className="font-bold flex justify-center">
                                        {status.name === 'open' ? 
                                            <div className='flex w-[10rem] justify-center items-center'>
                                                Open <ExclamationCircleIcon className='text-blue-500 ml-2 h-6 w-6 stroke-blue-900'/>
                                            </div>
                                            : status.name === 'completed' ? 
                                            <div className='flex w-[10rem] justify-center items-center'>
                                                Completed <CheckCircleIcon className='text-green-500 ml-2 mr-1 h-6 w-6 stroke-green-700'/>
                                            { batchContentAc === '0' ? <Spinner color='blue' className='h-5 w-5'/> :
                                            <div>
                                                <span className=''>{ batchContentAc }</span><span className='mr-2'>/{batchContent.length}</span>
                                            </div>
                                            }
                                            </div>
                                            : status.name === 'in-progress' ?                            
                                            <div className='flex w-[10rem] justify-center items-center'>
                                                Processing <ArrowPathIcon className='text-orange-400 animate-spin mx-2 h-6 w-6 stroke-orange-400'/> 
                                                <span className='animate-pulse'>{ batchContentAc }</span><span className='mr-2'>/{batchContent.length}</span>
                                            </div>
                                            : status.name === 'reset' ?                            
                                            <div className='flex w-[10rem] justify-center items-center animate-pulse'>
                                                Resetting <ArrowPathIcon className='text-orange-400 mx-2 h-6 w-6 stroke-orange-400'/> 
                                            </div>                                   
                                            : 
                                            <div className='flex w-[10rem] justify-center items-center'>
                                                Error 
                                                <Tooltip content={status.message}>
                                                <ExclamationTriangleIcon className='text-red-500 animate-pulse ml-2 h-6 w-6'/>
                                                </Tooltip>
                                                
                                            </div>
                                        }
                                    </Typography>
                                </td>
                                <td className="p-4 text-white">
                                    <Tooltip content="Refund Actions">
                                        <Link to={`/warehouse-tools/warehouse-refunds/refund-actions/${batchId}`}>
                                            <button 
                                            disabled={!refundAction}
                                            className={` ${!refundAction ? 'bg-opacity-50 cursor-not-allowed' :''} bg-blue-500 rounded-lg p-1 m-1 px-2 ${status.name !== 'completed' ? "hidden" : ''}`}>
                                            <EyeIcon className="h-5 w-5" />
                                            </button>  
                                        </Link>                   
                                    </Tooltip>
                                    <Tooltip content="Download file">
                                        <button className={`bg-blue-500 rounded-lg  ${status.name !== 'completed' ? "hidden" : ''} p-1 m-1 px-2`} 
                                        onClick={() => handleExportData(batchId, categories)}>
                                        {downloadingBatch[batchId] ? <Spinner/> : <ArrowDownTrayIcon className="h-5 w-5" />}
                                        </button>                      
                                    </Tooltip>
                                    {status.name !== 'open' ? (
                                        <Tooltip content="Reopen Batch">
                                            <button className="bg-orange-500 rounded-lg p-1 m-1 px-2" 
                                            onClick={() => handleReopenBatch(batchId)}
                                            >
                                                <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                                            </button>                      
                                        </Tooltip>                  
                                    ) : ""}
                                    <Tooltip content="Delete Batch">
                                        <button className="bg-red-500 rounded-lg p-1 m-1 px-2">
                                            <TrashIcon className="h-5 w-5" onClick={() => handleDeleteBatch(batchId)}/>
                                        </button>                      
                                    </Tooltip>
                                </td>
                            </tr>
                        )
                    }))
                }
                </tbody>
            </table>
            </Card>
            <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                />
            </div>
        </div>
    )
}
